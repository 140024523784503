@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
html,
body {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

/* Custom class for the page outline glow */
.page-outline-glow::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid blue;
  pointer-events: none;
  z-index: 9999;
  box-sizing: border-box;
}